<template>

  <div class="service__details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="4"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ serviceRequest.stringID }} - {{ serviceRequest.workFlow ? serviceRequest.workFlow.name : '' }}
            </h1>
            <p class="log_info">
              Created by {{ serviceRequest.createdBy ? serviceRequest.createdBy.name : '' }} on {{ dateFormatWithTime(serviceRequest.createdAt) }}<br>Last updated on {{ dateFormatWithTime(serviceRequest.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right mb-1"
        md="8"
        cols="12"
      >
        <div class="user_block ac_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Status</h6>
            <h5 class="text-capitalize">
              {{ serviceRequest.status ? serviceRequest.status.replace("-", " ") : '' }}
            </h5>
          </div>
          <div class="action_button">
            <b-button
              v-if="canViewThisAction('update', 'ServiceRequest') || canViewThisAction('update-status', 'ServiceRequest')"
              v-b-modal.serviceRequest-all-modal-update-status
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
            <b-button
              v-else
              v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
              variant="flat-primary"
              style="opacity: 0.65;"
              aria-readonly=""
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div>
        </div>
        <div class="user_block ac_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Service Form</h6>
            <b-button
              v-if="canViewThisAction('show', 'ServiceForm')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-primary align-middle detail-view-id cursor-pointer"
              :to="{ name: 'operation-service-forms-show', params: { id: serviceRequest.serviceForm ? serviceRequest.serviceForm._id : '0' } }"
            >
              {{ serviceRequest.serviceForm ? serviceRequest.serviceForm.stringID : '' }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-primary align-middle detail-view-id not-button"
            >
              {{ serviceRequest.serviceForm ? serviceRequest.serviceForm.stringID : '' }}
            </b-button>
          </div>
        </div>
        <div class="user_block">
          <div class="user_avatar">
            <img :src="serviceRequest.assignees ? (serviceRequest.assignees.length ? (serviceRequest.assignees[0].image ? serviceRequest.assignees[0].image : defaultImage) : defaultImage) : defaultImage">
          </div>
          <div class="user_info">
            <h6>Assignee</h6>
            <h5>{{ serviceRequest.assignees ? (serviceRequest.assignees.length ? (serviceRequest.assignees.length > 1 ? `${serviceRequest.assignees[0].name} + ${serviceRequest.assignees.length - 1}` : serviceRequest.assignees[0].name) : '') : '' }}</h5>
          </div>
          <div class="action_button">
            <b-button
              v-if="canViewThisAction('update', 'ServiceRequest') || canViewThisAction('update-assignee', 'ServiceRequest')"
              variant="flat-primary"
              @click="setDutyOfficerModal()"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
            <b-button
              v-else
              v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
              variant="flat-primary"
              style="opacity: 0.65;"
              aria-readonly=""
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 calllog-details serviceRequest_details"
    >
      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="9"
        >
          <h3>Applicant Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right mb-1"
          md="3"
        >
          <b-button
            v-if="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-applicant', 'ServiceForm')"
            variant="flat-primary"
            :to="{ name: 'operation-service-forms-edit', params: { id: serviceRequest.serviceForm ? serviceRequest.serviceForm._id : '0' }, query: { section: 'applicant_detail' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
            variant="flat-primary"
            style="opacity: 0.65;"
            aria-readonly=""
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Customer Name
            </b-th>
            <b-td>{{ serviceRequest.serviceForm ? (serviceRequest.serviceForm.customerName || '-') : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Mobile Number
            </b-th>
            <b-td>{{ serviceRequest.serviceForm ? (serviceRequest.serviceForm.customerContact || '-') : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Email
            </b-th>
            <b-td>{{ serviceRequest.serviceForm ? (serviceRequest.serviceForm.customerEmail || '-') : '-' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">
      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="9"
        >
          <h3>Particulars of Deceased</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right mb-1"
          md="3"
        >
          <b-button
            v-if="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-deceased', 'ServiceForm')"
            variant="flat-primary"
            :to="{ name: 'operation-service-forms-edit', params: { id: serviceRequest.serviceForm ? serviceRequest.serviceForm._id : '0' }, query: { section: 'deceased_detail' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
            variant="flat-primary"
            style="opacity: 0.65;"
            aria-readonly=""
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Niche No.
            </b-th>
            <b-td>
              <b-button
                v-if="serviceRequest.serviceForm && serviceRequest.serviceForm.deceasedNiche"
                variant="link"
                style="font-size: 14px; color: #104D9D; padding: 0; display: flex; line-height: 1.358rem;"
                :to="{ name: 'operation-service-requests-index', params: { type: 'all-service-requests' }, query: { search: serviceRequest.serviceForm.deceasedNiche, searchType: 'deceasedNiche' } }"
              >
                {{ serviceRequest.serviceForm.deceasedNiche }}
              </b-button>
              <span v-else>-</span>
            </b-td>
            <b-th width="230px">
              Name of Deceased
            </b-th>
            <b-td>{{ serviceRequest.serviceForm ? (serviceRequest.serviceForm.deceasedName || '-') : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Pedestal No.
            </b-th>
            <b-td>
              <b-button
                v-if="serviceRequest.serviceForm && serviceRequest.serviceForm.deceasedPedestal"
                variant="link"
                style="font-size: 14px; color: #104D9D; padding: 0; display: flex; line-height: 1.358rem;"
                :to="{ name: 'operation-service-requests-index', params: { type: 'all-service-requests' }, query: { search: serviceRequest.serviceForm.deceasedPedestal, searchType: 'deceasedPedestal' } }"
              >
                {{ serviceRequest.serviceForm.deceasedPedestal }}
              </b-button>
              <span v-else>-</span>
            </b-td>
            <b-th width="230px">
              Departed Date
            </b-th>
            <b-td>{{ serviceRequest.serviceForm ? (serviceRequest.serviceForm.departedDate ? dateFormat(serviceRequest.serviceForm.departedDate) : '-') : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Anling Lot No.
            </b-th>
            <b-td>
              <b-button
                v-if="serviceRequest.serviceForm && serviceRequest.serviceForm.deceasedAngling"
                variant="link"
                style="font-size: 14px; color: #104D9D; padding: 0; display: flex; line-height: 1.358rem;"
                :to="{ name: 'operation-service-requests-index', params: { type: 'all-service-requests' }, query: { search: serviceRequest.serviceForm.deceasedAngling, searchType: 'deceasedAngling' } }"
              >
                {{ serviceRequest.serviceForm.deceasedAngling }}
              </b-button>
              <span v-else>-</span>
            </b-td>
            <b-th width="230px">
              Age & Gender
            </b-th>
            <b-td>
              {{ serviceRequest.serviceForm ? (serviceRequest.serviceForm.deceasedAge || '-') : '-' }},
              <span class="text-capitalize">{{ serviceRequest.serviceForm ? (serviceRequest.serviceForm.deceasedGender || '-') : '-' }}</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="9"
        >
          <h3>Service Request Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right mb-1"
          md="3"
        >
          <b-button
            v-if="canViewThisAction('update', 'ServiceRequest') || canViewThisAction('update-detail', 'ServiceRequest')"
            variant="flat-primary"
            :to="{ name: 'operation-service-requests-edit', params: { id: serviceRequest._id ? serviceRequest._id : '0' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
            variant="flat-primary"
            style="opacity: 0.65;"
            aria-readonly=""
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="35%">
              Type
            </b-th>
            <b-td>
              {{ serviceRequest.workFlow ? serviceRequest.workFlow.name : '' }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="35%">
              Date & Time
            </b-th>
            <b-td v-if="serviceRequest.date">
              {{ dateFormat(serviceRequest.date) }}, {{ timeFormatFromDateString(serviceRequest.date) }}
            </b-td>
            <b-td v-else />
          </b-tr>
          <b-tr>
            <b-th width="35%">
              Service Location
            </b-th>
            <b-td>
              {{ serviceRequest.location ? serviceRequest.location : '-' }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="35%">
              Related File No.
            </b-th>
            <b-td>
              <b-button
                v-if="serviceRequest.fileNo"
                variant="link"
                style="font-size: 14px; color: #104D9D; padding: 0; display: flex; line-height: 1.358rem;"
                :to="{ name: 'operation-service-requests-index', params: { type: 'all-service-requests' }, query: { search: serviceRequest.fileNo } }"
              >
                {{ serviceRequest.fileNo }}
              </b-button>
              <span v-else>-</span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="35%">
              Remarks
            </b-th>
            <b-td>{{ serviceRequest.remarks ? serviceRequest.remarks : '-' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="9"
        >
          <h3>
            Service Descriptions
          </h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right mb-1"
          md="3"
        >
          <b-button
            v-if="canViewThisAction('update', 'ServiceRequest')"
            variant="flat-primary"
            :to="{ name: 'operation-service-requests-edit', params: { id: serviceRequest._id ? serviceRequest._id : '0' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
            variant="flat-primary"
            aria-readonly=""
            style="opacity: 0.65;"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>

      <b-table-simple
        borderless
        responsive
        class="service__description"
      >
        <b-tbody v-if="(serviceRequest.showServiceDescription && serviceRequest.showServiceDescription == true)">
          <b-tr
            v-for="(opt, key) in serviceRequest.serviceDescriptionValues"
            :key="'sr' + key"
          >
            <b-th
              v-if="(opt.children && opt.children.length) || opt.value"
              width="35%"
              style="vertical-align: top; padding: 5px 0px !important;"
            >
              {{ opt.key }}
              <div>
                <span
                  style="font-style: normal; font-weight: 400; font-size: 14px;"
                >
                  {{ opt.text }}
                </span>
              </div>
            </b-th>
            <b-td v-if="(opt.children && opt.children.length) || opt.value">
              <div v-if="opt.children">
                <div
                  v-for="(child, indx) in opt.children"
                  :key="'child' + indx"
                >
                  <span
                    v-if="Array.isArray(child.value)"
                    class="d-flex"
                  >
                    <span
                      class="mr-50"
                    >
                      {{ child.key }}
                    </span>
                    <div v-if="child.value.length">
                      <span
                        v-for="(option, index) in child.value"
                        :key="'cv' + index"
                      >
                        {{ option }} <span v-if="index + 1 != child.value.length">, </span>
                      </span>
                    </div>
                    <div v-else>
                      <span>-</span>
                    </div>
                  </span>
                  <span
                    v-else
                    class="d-flex"
                  >
                    <span
                      class="mr-50"
                    >
                      {{ child.key }}
                    </span>
                    <div v-if="child.value">
                      <span
                        v-if="(child.type && ['increment', 'short-answer', 'long-answer'].includes(child.type))"
                        class="green-text"
                      >
                        {{ child.value }}
                      </span>
                      <span v-else>{{ child.value }}</span>
                    </div>
                    <div v-else>
                      <span>{{ child.type == 'title-and-text' ? '' : '-' }}</span>
                    </div>
                  </span>
                </div>
              </div>
              <div v-else>
                <span
                  v-if="Array.isArray(opt.value)"
                  class="d-flex"
                >
                  <div v-if="opt.value.length">
                    <span
                      v-for="(option, index) in opt.value"
                      :key="'ov' + index"
                    >
                      {{ option }} <span v-if="index + 1 != opt.value.length">, </span>
                    </span>
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </span>
                <span
                  v-else
                  class="d-flex"
                >
                  <div v-if="opt.value">
                    <span
                      v-if="(opt.type && ['increment', 'short-answer', 'long-answer'].includes(opt.type))"
                      class="green-text"
                    >
                      {{ opt.value }}
                    </span>
                    <span v-else>{{ opt.value }}</span>
                  </div>
                  <div v-else>
                    <span>{{ opt.type == 'title-and-text' ? '' : '-' }}</span>
                  </div>
                </span>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-else-if="(serviceRequest.serviceDescription && serviceRequest.serviceDescription.length && !serviceRequest.serviceDescription[0].id)">
          <b-tr
            v-for="(opt, key) in serviceRequest.serviceDescription"
            :key="'sd' + key"
          >
            <b-th
              v-if="opt.key"
              width="35%"
            >
              {{ opt.key }}
              <div v-if="opt.text">
                <span
                  style="font-style: normal; font-weight: 400; font-size: 14px;"
                >
                  {{ opt.text }}
                </span>
              </div>
            </b-th>
            <b-td
              v-if="opt.key"
            >
              <span
                v-if="Array.isArray(opt.value)"
              >
                <div v-if="opt.value.length">
                  <span
                    v-for="(option, index) in opt.value"
                    :key="'sdov' + index"
                  >
                    {{ option }} <span v-if="index + 1 != opt.value.length">, </span>
                  </span>
                </div>
                <div v-else>
                  <span>-</span>
                </div>
              </span>
              <span v-else>{{ (opt.value && opt.value != 0) ? opt.value : (opt.text ? '' : '-') }}</span>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-else>
          <b-tr>
            <b-td style="padding-left: 0px !important;">
              No service descriptions selected.
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="9"
        >
          <h3>Payment Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right mb-1"
          md="3"
        >
          <b-button
            v-if="canViewThisAction('update-payment-sr', 'ServiceRequest')"
            variant="flat-primary"
            :to="{ name: 'operation-service-requests-edit', params: { id: serviceRequest._id || '0' }, query: { section: 'payment_details' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
            variant="flat-primary"
            aria-readonly=""
            style="opacity: 0.65;"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="35%">
              Payment Status
            </b-th>
            <b-td>{{ serviceRequest.paymentStatus || '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="35%">
              Payment Remarks
            </b-th>
            <b-td>{{ serviceRequest.paymentRemarks || '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="35%">
              Send SMS Reminder to Agent
            </b-th>
            <b-td>{{ serviceRequest.smsDate ? dateFormatWithTime(serviceRequest.smsDate) : 'No sms sent yet' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="button__actions">
        <b-button
          v-b-modal.modal-send-sms-agent
          variant="primary"
          class="mr-1"
          :class="{'mt-50': isMobile()}"
        >
          Remind Agent
        </b-button>
      </div>

      <hr class="dividerHR">

      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="9"
        >
          <h3>Agent Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right mb-1"
          md="3"
        >
          <b-button
            v-if="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-agent', 'ServiceForm')"
            variant="flat-primary"
            :to="{ name: 'operation-service-forms-edit', params: { id: serviceRequest.serviceForm ? serviceRequest.serviceForm._id : '0' }, query: { section: 'agent_detail' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
            variant="flat-primary"
            style="opacity: 0.65;"
            aria-readonly=""
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Agent Name
            </b-th>
            <b-td>{{ serviceRequest.serviceForm ? (serviceRequest.serviceForm.agentName || '-') : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Agent Code
            </b-th>
            <b-td>{{ serviceRequest.serviceForm ? (serviceRequest.serviceForm.agentCode || '-') : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Agent Mobile
            </b-th>
            <b-td>{{ serviceRequest.serviceForm ? (serviceRequest.serviceForm.agentContact || '-') : '-' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-card
      ref="taskListBlock"
      class="mb-3 p-2 without__bg must__bg extra__bg"
      no-body
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left booking_content_wrapper mb-2"
          cols="12"
          md="12"
        >
          <b-dropdown
            v-if="isMobile()"
            :text="currentTabTitle"
            variant="outline-primary"
            class="mobile-dropdown__arrow-adjust mb-50"
          >
            <b-dropdown-item
              @click="changeTab(0, 'Task')"
            >
              Task
            </b-dropdown-item>
            <b-dropdown-item
              @click="changeTab(1, 'Documents')"
            >
              Documents
            </b-dropdown-item>
          </b-dropdown>
          <b-tabs
            v-model="tabView"
            pills
            class="booking__content no-pr"
            :nav-class="[isMobile() ? 'd-none' : '']"
          >
            <!-- Tab: Information -->
            <b-tab class="">
              <template #title>
                <span class="d-none d-sm-inline">Task</span>
              </template>
              <b-row
                class="content-header v-sticky-sidebar-container service_request_task"
              >

                <!-- Content Left -->
                <b-col
                  class="content-header-left mb-2 top-info-right"
                  cols="12"
                  md="3"
                  style="position: relative;"
                >
                  <vue-sticky-sidebar
                    class="sidebar"
                    :top-spacing="topSpacing"
                    container-selector=".v-sticky-sidebar-container"
                    inner-wrapper-selector=".sidebar__inner"
                  >
                    <scrollactive
                      class="my-nav"
                      active-class="high-opacity"
                      :offset="200"
                      :duration="800"
                      :modify-url="false"
                      bezier-easing-value=".5,0,.35,1"
                    >
                      <a
                        v-for="(task, key) in serviceRequestTasks"
                        :id="'#task-' + (key + 1)"
                        :key="'task' + key"
                        :href="'#task-' + (key + 1)"
                        class="scrollactive-item"
                      >
                        <b-card
                          no-body
                          class="sticky_sidebar mb-50 low-opacity"
                        >
                          <div class="event-info p-1">
                            <b-badge
                              :variant="resolveTaskStatusVariant(task.status)"
                              class="text-capitalize"
                            >
                              {{ task.status }}
                            </b-badge>
                            <div
                              v-for="(assignee, index) in task.assignees"
                              :key="'assignee' + index"
                            >
                              <b-avatar
                                v-if="assignee.image"
                                v-b-tooltip.hover.top="assignee.name"
                                :src="assignee.image"
                              />
                              <b-avatar
                                v-else
                                v-b-tooltip.hover.top="assignee.name"
                                :text="resolveAcronymName(assignee.name)"
                              />
                            </div>
                            <b-button
                              variant="flat-primary"
                              @click="setTaskData(task, key)"
                            >
                              <feather-icon
                                icon="EditIcon"
                                class="mr-50"
                                size="16"
                              />
                            </b-button>
                            <h5>{{ key + 1 }}. {{ task.taskName }}</h5>
                            <p v-if="task.dueDate">{{ resolveDateAndText(task.dueDate) }}</p>
                          </div>
                        </b-card>
                      </a>
                    </scrollactive>
                  </vue-sticky-sidebar>
                </b-col>

                <!-- Content Right -->
                <b-col
                  class="content-header-right d-md-block mb-1"
                  md="9"
                  cols="12"
                  style="position: relative;"
                >
                  <b-card
                    v-for="(task, key) in serviceRequestTasks"
                    :id="'task-' + (key + 1)"
                    :key="key"
                    no-body
                    class="mb-1"
                    :class="{'task_completed': task.completed == true, 'task_skipped': task.skipped == true }"
                  >
                    <template
                      #header
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="button"
                        variant="flat-primary"
                        class="primary-button-with-background mr-3"
                      >
                        <feather-icon
                          :icon="task.taskBlocks.length > 1 ? 'GridIcon' : (task.taskBlocks[0].block.icon ? task.taskBlocks[0].block.icon : '')"
                          class=""
                          size="20"
                        />
                      </b-button>
                      <h3
                        class="align-middle mr-auto"
                        :class="{'text-success': task.completed == true }"
                      >
                        {{ key + 1 }}. {{ task.taskName }}
                      </h3>
                      <div v-if="task.dueDate">
                        {{ resolveDateAndText(task.dueDate) }}
                      </div>
                      <div
                        v-for="(assignee, index) in task.assignees"
                        :key="'task-assignee' + index"
                      >
                        <b-avatar
                          v-if="assignee.image"
                          v-b-tooltip.hover.top="assignee.name"
                          :src="assignee.image"
                        />
                        <b-avatar
                          v-else
                          v-b-tooltip.hover.top="assignee.name"
                          :text="resolveAcronymName(assignee.name)"
                        />
                      </div>
                    </template>
                    <div
                      :class="{'low-opacity-task': (task.skipped == true || task.completed == true) }"
                      class="task__list"
                    >
                      <b-card
                        v-for="(taskBlock, tkey) in task.taskBlocks"
                        id=""
                        :key="'tb-key' + tkey"
                        no-body
                        class="mb-50"
                        :class="{'low-opacity-div': (task.skipped == true || task.completed == true) }"
                      >
                        <email-template
                          v-if="taskBlock.block.name == 'Send Email With Attachment'"
                          :input-values.sync="taskBlock.inputValues"
                          :field-values="taskBlock.fieldValues"
                          :custom-fields="taskBlock.customFields"
                          :task-block="taskBlock._id"
                          :submit-date.sync="taskBlock.submitDate"
                          :service-form="serviceRequest.serviceForm"
                          :service-request-id="serviceRequest._id"
                          @updateCount="triggerClick"
                        />
                        <file-upload-template
                          v-if="taskBlock.block.name == 'File Upload'"
                          :input-values.sync="taskBlock.inputValues"
                          :tab-view.sync="tabView"
                          :field-values="taskBlock.fieldValues"
                          :custom-fields="taskBlock.customFields"
                          :task-block="taskBlock._id"
                          :submit-date.sync="taskBlock.submitDate"
                          :service-form="serviceRequest.serviceForm"
                          :service-request-id="serviceRequest._id"
                          @fileChanged="setData()"
                          @updateCount="triggerClick"
                        />
                        <send-sms-template
                          v-if="taskBlock.block.name == 'Send SMS Message'"
                          :input-values.sync="taskBlock.inputValues"
                          :field-values="taskBlock.fieldValues"
                          :custom-fields="taskBlock.customFields"
                          :task-block="taskBlock._id"
                          :submit-date.sync="taskBlock.submitDate"
                          :service-form="serviceRequest.serviceForm"
                          :service-request-id="serviceRequest._id"
                          @updateCount="triggerClick"
                        />
                        <send-telegram-message-template
                          v-if="taskBlock.block.name == 'Send Telegram Message'"
                          :input-values.sync="taskBlock.inputValues"
                          :field-values="taskBlock.fieldValues"
                          :custom-fields="taskBlock.customFields"
                          :task-block="taskBlock._id"
                          :submit-date.sync="taskBlock.submitDate"
                          :service-form="serviceRequest.serviceForm"
                          :service-request-id="serviceRequest._id"
                          :service-request-string="serviceRequest.stringID"
                          @updateCount="triggerClick"
                        />
                        <input-field-template
                          v-if="taskBlock.block.name == 'Form' || taskBlock.block.name == 'Input Fields'"
                          :input-values.sync="taskBlock.inputValues"
                          :field-values="taskBlock.fieldValues"
                          :custom-fields="taskBlock.customFields"
                          :task-block="taskBlock._id"
                          :submit-date.sync="taskBlock.submitDate"
                          :service-form="serviceRequest.serviceForm"
                          :service-request-id="serviceRequest._id"
                          @updateCount="triggerClick"
                        />
                        <form-template
                          v-if="taskBlock.block.name == 'Forms'"
                          :input-values.sync="taskBlock.inputValues"
                          :field-values="taskBlock.fieldValues"
                          :custom-fields="taskBlock.customFields"
                          :task-block="taskBlock._id"
                          :submit-date.sync="taskBlock.submitDate"
                          :service-form="serviceRequest.serviceForm"
                          :service-request-id="serviceRequest._id"
                          :form-options="formOptions"
                          :service-request-forms="serviceRequestForms"
                          :task="task"
                          @updateCount="triggerClick"
                        />
                        <link-template
                          v-if="taskBlock.block.name == 'Link'"
                          :input-values.sync="taskBlock.inputValues"
                          :field-values="taskBlock.fieldValues"
                          :custom-fields="taskBlock.customFields"
                          :task-block="taskBlock._id"
                          :submit-date.sync="taskBlock.submitDate"
                          :service-form="serviceRequest.serviceForm"
                          :service-request-id="serviceRequest._id"
                          @updateCount="triggerClick"
                        />
                        <verification-template
                          v-if="taskBlock.block.name == 'Verification'"
                          :input-values.sync="taskBlock.inputValues"
                          :field-values="taskBlock.fieldValues"
                          :custom-fields="taskBlock.customFields"
                          :task-block="taskBlock._id"
                          :submit-date.sync="taskBlock.submitDate"
                          :service-form="serviceRequest.serviceForm"
                          :service-request-id="serviceRequest._id"
                          @updateCount="triggerClick"
                        />
                        <inventory-request-template
                          v-if="taskBlock.block.name == 'Inventory Request'"
                          :input-values.sync="taskBlock.inputValues"
                          :field-values="taskBlock.fieldValues"
                          :custom-fields="taskBlock.customFields"
                          :task-block="taskBlock._id"
                          :submit-date.sync="taskBlock.submitDate"
                          :service-form="serviceRequest.serviceForm"
                          :service-request-id="serviceRequest._id"
                          :service-request-string-id="serviceRequest.stringID"
                          :service-date="serviceRequest.date"
                          :file-no="serviceRequest.fileNo"
                          @updateCount="triggerClick"
                        />
                      </b-card>
                    </div>
                    <b-card-footer
                      :class="{'task_completed': task.completed == true, 'task_skipped': task.skipped == true }"
                      class="secondary-card-footer p-1"
                    >
                      <div v-if="task.completed == false && task.skipped == false">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          type="button"
                          class=""
                          @click="markTaskAsComplete(task, key)"
                        >
                          <feather-icon
                            icon="CheckSquareIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">Mark as complete</span>
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="flat-primary"
                          type="button"
                          class="ml-50"
                          @click="skipTask(task, key)"
                        >
                          Skip
                        </b-button>
                      </div>
                      <div v-else-if="task.completed == true">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-success"
                          type="button"
                          class=""
                        >
                          <feather-icon
                            icon="CheckSquareIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">Marked as completed</span>
                        </b-button>

                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="flat-primary"
                          type="button"
                          class="mt-50"
                          @click="unmarkTask(task, key)"
                        >
                          Undo
                        </b-button>
                        <span class="float-right mt-75">
                          This task was completed on <span class="text-bold-black">{{ dateFormatWithTime(task.completedDate) }}</span> by <span class="text-bold-black">{{ task.completedBy ? task.completedBy.name : '' }}</span>
                        </span>
                      </div>
                      <div v-else-if="task.skipped == true">
                        <!-- <b-row class="skip-background">
                      <b-col md="11">
                        <div class="p-1">
                          <span class="skip-text">
                            Skipped! This task was skipped on {{ dateFormatWithTime(task.skippedDate) }}
                          </span>
                        </div>
                      </b-col>
                      <b-col md="1">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="flat-primary"
                          type="button"
                          class="mt-50"
                          @click="unskipTask(task, key)"
                        >
                          Undo
                        </b-button>
                      </b-col>
                    </b-row> -->
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-primary"
                          type="button"
                          class=""
                        >
                          <feather-icon
                            icon="SkipForwardIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">Skipped</span>
                        </b-button>

                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="flat-primary"
                          type="button"
                          class=""
                          @click="unskipTask(task, key)"
                        >
                          Undo
                        </b-button>
                        <span class="float-right mt-75">
                          This task was skipped on <span class="text-bold-black">{{ dateFormatWithTime(task.skippedDate) }}</span> by <span class="text-bold-black">{{ task.skippedBy ? task.skippedBy.name : '' }}</span>
                        </span>
                      </div>
                    </b-card-footer>
                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab class="">
              <template #title>
                <span class="d-none d-sm-inline">Documents</span>
              </template>
              <b-card
                no-body
                class="shadow-body"
              >
                <b-card-body>
                  <h5
                    style="font-size: 21px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  margin-bottom: 30px"
                  >All Forms</h5>

                  <b-row
                    v-if="!isMobile()"
                    class="mb-2"
                  >
                    <b-col
                      cols="12"
                      md="6"
                      class=""
                    >
                      <span class="weight-500 fs-15">Forms</span>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class=""
                    >
                      <span class="weight-500 fs-15">Status</span>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                      class=""
                    >
                      <span class="weight-500 fs-15">Signed PDF Copy</span>
                    </b-col>
                  </b-row>
                  <div
                    v-for="(form, key) in serviceRequestForms"
                    :key="'forms' + key"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="6"
                        class="hover-td"
                      >
                        <div
                          v-if="isMobile()"
                        >
                          <span class="weight-500 fs-15">Forms</span>
                        </div>
                        <div
                          class=""
                        >
                          <span class="">{{ formName(form.formID) }}</span>
                          <b-button
                            v-b-tooltip.hover.top="'Edit form'"
                            variant="flat-primary"
                            class="ml-75 wf-action-button"
                            style="float: none; margin-top: 0 !important;"
                            :to="{ name: 'operation-service-requests-form-view', params: { id: serviceRequest._id }, query: { form: form._id } }"
                          >
                            <feather-icon
                              icon="EditIcon"
                              size="16"
                            />
                          </b-button>

                          <b-button
                            v-if="form.status == 'Signed and Sent' && form.pdfs.length"
                            v-b-tooltip.hover.top="'Download without password'"
                            variant="flat-primary"
                            class="p-0 wf-action-button"
                            style="float: none; margin-top: 0 !important;"
                            @click="downloadSRForm(form._id, form.pdfs[0].name)"
                          >
                            <feather-icon
                              icon="DownloadIcon"
                              size="16"
                            />
                          </b-button>
                        </div>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                        class=""
                      >
                        <div
                          v-if="isMobile()"
                          class="mt-75"
                        >
                          <span class="weight-500 fs-15">Status</span>
                        </div>
                        <b-badge
                          :variant="`light-${resolveFormStatusVariant(form.status)}`"
                          :to="{ name: 'operation-service-requests-form-view', params: { id: serviceRequest._id }, query: { form: form._id } }"
                          style="margin-top: 7px; padding: 4px 9px; font-size: 14px; line-height: 18px; font-weight: 400;"
                          :class="{ 'mt-50': isMobile() }"
                        >
                          {{ form.status }}
                        </b-badge>
                        <br>
                        <span v-if="form.pdfSentDate">
                          {{ dateFormatWithTime(form.pdfSentDate) }}
                        </span>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                        class="list-item"
                      >
                        <div
                          v-if="isMobile()"
                          class="mt-75"
                        >
                          <span class="weight-500 fs-15">Signed PDF Copy</span>
                        </div>
                        <div v-if="form.pdfs.length">
                          <app-collapse
                            v-if="form.pdfs.length > 1"
                            class="mt-50 reverse-icon"
                          >
                            <app-collapse-item title="Urn Repository Form">
                              <template
                                #header
                              >
                                <b-row
                                  class="full-width"
                                >
                                  <!-- Content Left -->
                                  <b-col
                                    class="content-header-left"
                                    cols="12"
                                    md="12"
                                  >
                                    <div
                                      class="attachment-item"
                                    >
                                      <b-img
                                        left
                                        height="40"
                                        :src="require('@/assets/images/admin/doc_file.png')"
                                        alt="Left image"
                                      />
                                      <strong>
                                        <b-link
                                          :href="form.pdfs[0] ? form.pdfs[0].data : ''"
                                          target="_blank"
                                        >
                                          {{ form.pdfs[0] ? form.pdfs[0].name : '' }}
                                        </b-link>
                                      </strong>
                                      <b-badge
                                        variant="warning"
                                        class="ml-auto"
                                      >
                                        Latest
                                      </b-badge>
                                    </div>
                                  </b-col>
                                </b-row>
                              </template>
                              <b-row
                                v-for="(pdf, index) in form.pdfs"
                                :key="'pdf' + index"
                                class="full-width"
                              >
                                <b-col
                                  v-if="index > 0"
                                  class="content-header-left mt-50"
                                  cols="12"
                                  md="12"
                                >
                                  <div
                                    class="attachment-item"
                                  >
                                    <b-img
                                      left
                                      height="40"
                                      :src="require('@/assets/images/admin/doc_file.png')"
                                      alt="Left image"
                                    />
                                    <strong>
                                      <b-link
                                        :href="pdf ? pdf.data : ''"
                                        target="_blank"
                                      >
                                        {{ pdf ? pdf.name : '' }}
                                      </b-link>
                                    </strong>
                                  </div>
                                </b-col>
                              </b-row>
                            </app-collapse-item>
                          </app-collapse>
                          <div
                            v-else
                            class="attachment-item mr-28"
                          >
                            <b-img
                              left
                              height="40"
                              :src="require('@/assets/images/admin/doc_file.png')"
                              alt="Left image"
                            />
                            <strong>
                              <b-link
                                :href="form.pdfs[0] ? form.pdfs[0].data : ''"
                                target="_blank"
                              >
                                {{ form.pdfs[0] ? form.pdfs[0].name : '' }}
                              </b-link>
                            </strong>
                            <b-badge
                              variant="warning"
                              class="ml-auto"
                            >
                              Latest
                            </b-badge>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <hr
                      v-if="key < (serviceRequestForms.length - 1)"
                      class="my-50"
                    >
                  </div>
                </b-card-body>
              </b-card>

              <b-card
                no-body
                class="shadow-body"
              >
                <b-card-body>
                  <h3 class="">
                    Uploaded Documents
                  </h3>
                  <div
                    v-if="uploadedFiles.length || ancestralFiles.length"
                  >
                    <b-row
                      v-for="(block, index) in ancestralFiles"
                      :key="'af-files' + index"
                    >
                      <!-- Content Left -->
                      <b-col
                        v-for="(file, key) in block"
                        :key="'file-block' + key"
                        class="content-header-left mb-2"
                        cols="12"
                        md="4"
                      >
                        <div
                          v-if="file.data && file.data.startsWith('http')"
                        >
                          <div
                            class="attachment-item"
                          >
                            <b-img
                              v-if="file.type.includes('image')"
                              left
                              height="40"
                              :src="require('@/assets/images/admin/doc_jpg.png')"
                              alt="Left image"
                            />
                            <b-img
                              v-if="file.type.includes('application')"
                              left
                              height="40"
                              :src="require('@/assets/images/admin/doc_file.png')"
                              alt="Left image"
                            />
                            <strong>
                              <b-link
                                :href="file ? file.data : ''"
                                target="_blank"
                                class="high_opacity"
                              >
                                {{ file ? file.name : '' }}
                              </b-link>
                            </strong>
                            <span>{{ file ? `${file.size}mb` : '' }}</span>
                            <!-- <feather-icon
                          icon="XIcon"
                          class="remove__doc"
                          size="16"
                          @click="removeAttachment(index, file.data, block._id)"
                        /> -->
                          </div>
                          <div class="doc__desc">
                            <span v-if="file.description">
                              {{ file.description }}
                            </span>
                            <!-- <span v-else>
                          <button @click="updateDescription(index, key, block._id)">Add a description</button>
                        </span>
                        <span
                          v-if="file.description"
                          class="edit__desc"
                        >
                          <b-button
                            variant="flat-primary"
                            @click="updateDescription(index, key, block._id)"
                          >
                            <feather-icon
                              icon="EditIcon"
                              class="mr-50"
                              size="18"
                            />
                          </b-button>
                        </span> -->
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row
                      v-for="(block, index) in uploadedFiles"
                      :key="'uf' + index"
                    >
                      <!-- Content Left -->
                      <b-col
                        v-for="(file, key) in block.values"
                        :key="'fb-val' + key"
                        class="content-header-left mb-2"
                        cols="12"
                        md="4"
                      >
                        <div
                          v-if="file.data && file.data.startsWith('http')"
                        >
                          <div
                            class="attachment-item"
                          >
                            <b-img
                              v-if="file.type.includes('image')"
                              left
                              height="40"
                              :src="require('@/assets/images/admin/doc_jpg.png')"
                              alt="Left image"
                            />
                            <b-img
                              v-if="file.type.includes('application')"
                              left
                              height="40"
                              :src="require('@/assets/images/admin/doc_file.png')"
                              alt="Left image"
                            />
                            <strong>
                              <b-link
                                :href="file ? file.data : ''"
                                target="_blank"
                                class="high_opacity"
                              >
                                {{ file ? file.name : '' }}
                              </b-link>
                            </strong>
                            <span>{{ file ? `${file.size}mb` : '' }}</span>
                            <!-- <feather-icon
                              icon="XIcon"
                              class="remove__doc"
                              size="16"
                              @click="removeAttachment(index, file.data, block._id)"
                            /> -->
                          </div>
                          <div class="doc__desc">
                            <span v-if="file.description">
                              {{ file.description }}
                            </span>
                            <!-- <span v-else>
                              <button @click="updateDescription(index, key, block._id)">Add a description</button>
                            </span>
                            <span
                              v-if="file.description"
                              class="edit__desc"
                            >
                              <b-button
                                variant="flat-primary"
                                @click="updateDescription(index, key, block._id)"
                              >
                                <feather-icon
                                  icon="EditIcon"
                                  class="mr-50"
                                  size="18"
                                />
                              </b-button>
                            </span> -->
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div
                    v-else
                    class="py-1 mt-75"
                    style="background: rgba(239, 239, 239, 0.30);"
                  >
                    <div
                      class="empty_block"
                    >
                      <h4>No Document Yet</h4>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-update-duty-officer"
      ref="update-duty-officer-modal"
      modal-class="sign-modal-adjust"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Assignee"
      @ok="dutyOfficerSubmitForm"
    >
      <b-form @submit.prevent="dutyOfficerSubmitForm">
        <validation-observer
          ref="serviceRequestDutyOfficerEditForm"
        >
          <b-form-group
            label="Assignee"
            label-for="h-serviceRequest-dutyOfficer"
          >
            <validation-provider
              #default="{ errors }"
              name="Assignee"
              vid="status"
              rules=""
            >
              <v-select
                id="h-serviceRequest-dutyOfficer"
                v-model="dutyOfficer"
                label="name"
                multiple
                :options="dutyOfficerOptions"
                :reduce="name => name._id"
                :clearable="true"
                placeholder="Select an option"
                @input="checkForRequestAssigneeSchedule"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="dutyOfficerValidation"
                class="text-danger"
              >
                {{ dutyOfficerError }}
              </small>
            </validation-provider>
          </b-form-group>
          <div
            v-if="assignmentError"
            class="service_assign_error"
          >
            <h5>Warning</h5>
            <div>
              <p>Assingee(s) may not be available for the the following Service Requests. Are you sure you want to proceed?</p>
              <ul>
                <li
                  v-for="(option, index) in assignmentErrorOptions"
                  :key="'aeo' + index"
                >
                  {{ option.date }} - {{ option.request }} - {{ option.name }} ({{ option.leave }})
                </li>
              </ul>
            </div>
          </div>
          <b-form-checkbox
            v-model="confirmVal"
            value="accepted"
            unchecked-value=""
            class="custom-control-primary"
          >
            Send message about these assignments to related service Agent(s)
          </b-form-checkbox>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="serviceRequest-all-modal-update-status"
      ref="update-status-modal"
      modal-class="sign-modal-adjust"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="allserviceRequestStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="service-request-task-update-modal"
      ref="service-request-task-update-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal sign-modal-adjust"
      ok-variant="success"
      ok-title="Confirm Changes"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Edit Task"
      @ok="submitTaskData"
    >
      <b-form @submit.prevent="submitTaskData">
        <validation-observer
          ref="serviceRequestTaskEditForm"
        >
          <b-form-group
            label="Task Name*"
            label-for="h-booking-taskName"
          >
            <validation-provider
              #default="{ errors }"
              name="Task Name"
              vid="taskName"
              rules="required"
            >
              <b-form-input
                id="h-booking-taskName"
                v-model="currentTask.taskName"
                placeholder="Task Name"
                :state="errors.length > 0 ? false : null"
                name="taskName"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Due Date*"
            label-for="dueDate"
          >
            <b-input-group>
              <flat-pickr
                id="dueDate"
                v-model="currentTask.dueDate"
                class="form-control flat-pickr-group"
                placeholder=""
                :config="flatPickrConfig"
                @input="checkForAssigneeSchedule"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="CalendarIcon"
                  class="cursor-pointer"
                  data-toggle
                  size="18"
                />
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="dateValidation"
              class="text-danger"
            >
              {{ dateError }}
            </small>
          </b-form-group>
          <b-form-group
            label="Assignee*"
            label-for="h-event-assignee"
          >
            <p>If you wish to assign or reassign the requests, please select their name from the list below:</p>
            <validation-provider
              #default="{ errors }"
              name="Assignee"
              vid="assignee"
              rules="required"
            >
              <v-select
                id="h-event-assignee"
                v-model="currentTask.assignees"
                label="name"
                multiple
                :options="dutyOfficerOptions"
                :reduce="name => name._id"
                :clearable="false"
                @input="checkForAssigneeSchedule"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div
            v-if="assignmentError"
            class="service_assign_error"
          >
            <h5>Warning</h5>
            <div>
              <p>Assingee(s) may not be available for the the task. Are you sure you want to proceed?</p>
              <ul>
                <li
                  v-for="(option, index) in assignmentErrorOptions"
                  :key="'aoerr' + index"
                >
                  {{ option.date }} - {{ option.name }} ({{ option.leave }})
                </li>
              </ul>
            </div>
          </div>
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="currentTask.status"
                label="title"
                :options="taskStatusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <!-- <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small> -->
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-send-sms-agent"
      modal-class="sign-modal-adjust"
      title=""
      centered
      no-stacking
      hide-footer
      header-class="front-modal"
    >
      <h3>Remind agent about outstanding payment</h3>
      <p>Applicant hasn’t made full payment yet. Please remind Agent {{ serviceRequest.agentName ? serviceRequest.agentName : '-' }} by sending SMS to <strong>(65) {{ serviceRequest.agentContact ? serviceRequest.agentContact : '-' }}</strong></p>
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-agent-phone-number"
            v-model="smsText"
            placeholder="E.g. Please remind to make payment"
          />
          <small
            v-if="smsTextValidation"
            class="text-danger"
          >
            {{ smsTextError }}
          </small>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          sm="12"
          cols="12"
        >
          <b-button
            v-b-modal.modal-send-sms-customer
            variant="link"
          >
            Send to applicant instead
          </b-button>
        </b-col>
        <b-col
          md="6"
          sm="12"
          cols="12"
        >
          <div class="demo-inline-spacing ml-auto d-flex justify-content-between">
            <b-button
              variant="flat-primary"
              @click="closeAgentSmsModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="!smsText || !agentContact"
              @click="sendSms('agent')"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Send</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-send-sms-customer"
      modal-class="sign-modal-adjust"
      title=""
      centered
      no-stacking
      hide-footer
      header-class="front-modal"
    >
      <h3>Remind applicant about outstanding payment</h3>
      <p>Applicant hasn’t made full payment yet. Please remind <strong>applicant</strong> by sending SMS to <strong>(65) {{ serviceRequest.customerContact ? serviceRequest.customerContact : '-' }}</strong></p>
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="smsText"
            placeholder="E.g. Please remind to make payment"
          />
          <small
            v-if="smsTextValidation"
            class="text-danger"
          >
            {{ smsTextError }}
          </small>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          sm="12"
          cols="12"
        >
          <b-button
            v-b-modal.modal-send-sms-agent
            variant="link"
          >
            Send to agent instead
          </b-button>
        </b-col>
        <b-col
          md="6"
          sm="12"
          cols="12"
        >
          <div class="demo-inline-spacing ml-auto d-flex justify-content-between">
            <b-button
              variant="flat-primary"
              @click="closeCustomerSmsModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="!smsText || !customerContact"
              @click="sendSms('customer')"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Send</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vueStickySidebar from 'vue-sticky-sidebar'
import {
  BCard, BCardBody, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BForm, BFormGroup, BBadge, BAvatar, BCardFooter, BFormTextarea,
  BFormInput, BInputGroup, BInputGroupAppend, BFormCheckbox, VBTooltip, VBToggle, BTabs, BTab, BImg, BLink, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store/index'
import { required } from '@validations'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import EmailTemplate from './EmailTemplate.vue'
import FileUploadTemplate from './FileUploadTemplate.vue'
import SendSmsTemplate from './SendSmsTemplate.vue'
import SendTelegramMessageTemplate from './SendTelegramMessageTemplate.vue'
import FormTemplate from './FormTemplate.vue'
import InputFieldTemplate from './InputFieldTemplate.vue'
import LinkTemplate from './LinkTemplate.vue'
import VerificationTemplate from './VerificationTemplate.vue'
import InventoryRequestTemplate from './InventoryRequestTemplate.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BForm,
    BFormGroup,
    BBadge,
    BAvatar,
    BCardFooter,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BTabs,
    BTab,
    BImg,
    BLink,
    BDropdown,
    BDropdownItem,
    BFormTextarea,

    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,

    'vue-sticky-sidebar': vueStickySidebar,
    AppCollapse,
    AppCollapseItem,

    EmailTemplate,
    FileUploadTemplate,
    SendSmsTemplate,
    SendTelegramMessageTemplate,
    InputFieldTemplate,
    FormTemplate,
    LinkTemplate,
    VerificationTemplate,
    InventoryRequestTemplate,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      topSpacing: 135,
      serviceRequest: {},
      serviceRequestID: '618235a2a3c26a7dd2baf81a',
      stringID: '',
      status: 'completed',
      statusValidation: false,
      statusError: 'Valid status is required',
      dutyOfficer: [],
      dutyOfficerValidation: false,
      dutyOfficerError: 'Valid duty officer is required',
      dateValidation: false,
      dateError: 'Date is required',
      dutyOfficerOptions: [],
      formOptions: [],
      serviceRequestForms: [],
      serviceRequestActivityLogs: [],
      uploadedFiles: [],
      ancestralFiles: [],
      tabView: 0,
      statusDataOptions: [
        { title: 'Awaiting Approval', code: 'awaiting approval' },
        { title: 'Completed', code: 'completed' },
        { title: 'Pending', code: 'pending' },
      ],
      taskStatusDataOptions: [
        { title: 'Completed', code: 'completed' },
        { title: 'Pending', code: 'pending' },
        { title: 'Skipped', code: 'skipped' },
      ],
      serviceRequestTasks: [],
      currentKey: '',
      currentTask: {},
      flatPickrConfig: {
        wrap: true, enableTime: true, dateFormat: 'd/m/Y, H:i', disableMobile: true,
      },
      assignmentError: false,
      alreadyCheckedSchedule: false,
      assignmentErrorOptions: [],
      confirmVal: 'accepted',
      showServiceDescription: false,
      currentTabTitle: 'Task',
      smsText: '',
      agentContact: '',
      customerContact: '',
      smsTextValidation: false,
      smsTextError: '',
      taskLoaderCounter: 0,
      totalTask: 0,

      required,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo.svg'),
    }
  },
  created() {
    this.$http.get(`operation/service-requests/${this.$route.params.id}/show`, { params: { department: true } })
      .then(async response => {
        this.serviceRequest = response.data.data || {}
        this.dutyOfficerOptions = response.data.dutyOfficers || []
        this.serviceRequestTasks = response.data.serviceRequestTasks || []
        this.formOptions = response.data.formOptions
        this.serviceRequestForms = response.data.serviceRequestForms
        this.totalTask = this.serviceRequestTasks.reduce((n, { taskBlocks }) => n + taskBlocks.length, 0)

        this.customerContact = this.serviceRequest.customerContact
        this.agentContact = this.serviceRequest.agentContact
        this.smsText = `Dear Agent,
Good day to you, please be informed that you have an outstanding payment for the following service:

Service Date: ${this.dateFormat(this.serviceRequest.date)}
Service Time: ${this.timeFormatFromDateString(this.serviceRequest.date)}
Service Details: ${this.serviceRequest.workFlowName}
  
Offerings: $
Chanting: $
Others: $

Total Amount: $

Please proceed to the Customer Service counter to make the payment.

Thank you and wishing you a great day ahead. 

Nirvana Memorial Garden
${this.user.name}

[This is an automated message - Please do not reply directly to this text message].`

        document.title = `${response.data.data.stringID} - ${response.data.data.workFlow.name} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`

        this.setData()
        await this.$nextTick()
        const { section } = this.$route.query
        if (section) {
          const element = this.$refs[section]
          if (element) {
            const top = element.offsetTop - 135
            window.scrollTo({
              top,
              behavior: 'smooth',
            })
          }
        }

        // setTimeout(() => {
        //   const taskSection = this.$route.query.section
        //   if (taskSection) {
        //     document.getElementById(taskSection).click()
        //   }
        // }, 2000)
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     console.log('ok')
  //     const taskSection = this.$route.query.section
  //     if (taskSection) {
  //       document.getElementById(taskSection).click()
  //     }
  //   }, 500)
  // },
  methods: {
    triggerClick() {
      // eslint-disable-next-line no-plusplus
      this.taskLoaderCounter++
      if (this.totalTask === this.taskLoaderCounter) {
        const taskSection = this.$route.query.section
        if (taskSection) {
          document.getElementById(taskSection).click()
        }
      }
    },
    downloadSRForm(id, name) {
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${id}/detail`)
      formData.append('landscape', false)
      formData.append('pageRanges', 1)

      this.$http.post('operation/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = name
          link.click()

          link.remove()
        })
    },
    changeTab(index, tabName) {
      this.tabView = index
      this.currentTabTitle = tabName
    },
    formName(id) {
      const form = this.formOptions.find(o => o._id === id)
      if (form) {
        let fName = form.name
        if (form.formNumber && form.formNumber !== '-') {
          fName += ` (${form.formNumber})`
        }
        if (form.revision && form.revision !== '-') {
          fName += ` (${form.revision})`
        }
        return fName
      }

      return ''
    },
    resolveFormStatusVariant(status) {
      if (status === 'Signed and Sent') return 'success'
      if (status === 'Not required') return 'secondary'
      if (status === 'To prepare') return 'primary'
      return 'warning'
    },
    closeCustomerSmsModal() {
      this.$root.$emit('bv::hide::modal', 'modal-send-sms-customer', '')
    },
    closeAgentSmsModal() {
      this.$root.$emit('bv::hide::modal', 'modal-send-sms-agent', '')
    },
    sendSms(type) {
      let phone = ''
      if (type === 'agent') {
        phone = this.agentContact
        this.closeAgentSmsModal()
      } else {
        phone = this.customerContact
        this.closeCustomerSmsModal()
      }

      this.$http
        .post('operation/service-requests/send-sms', { phone, message: this.smsText, serviceRequest: this.$route.params.id })
        .then(response => {
          this.serviceRequest.smsDate = moment()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resolveDateAndText(date) {
      const timezone = process.env.VUE_APP_TIME_ZONE
      let text = ''
      if (moment(date).utcOffset(timezone).isSame(moment().utcOffset(timezone), 'day')) {
        text = 'Due Today'
      } else if (moment(date).utcOffset(timezone).isBefore(moment().utcOffset(timezone), 'day')) {
        text = `Due since ${moment(date).utcOffset(timezone).format('DD/MM/YYYY, hh:mma')}`
      } else if (moment(date).utcOffset(timezone).isAfter(moment().utcOffset(timezone), 'day')) {
        text = `Due on ${moment(date).utcOffset(timezone).format('DD/MM/YYYY, hh:mma')}`
      }

      return text
    },
    setData() {
      this.serviceRequestID = this.serviceRequest._id
      this.status = this.serviceRequest.status
      this.stringID = this.serviceRequest.stringID
      this.dutyOfficer = this.serviceRequest.assignees
      this.assignmentErrorOptions = []
      this.assignmentError = false
      this.serviceRequest.serviceDescription.every(opt => {
        if (opt.key) {
          if (Array.isArray(opt.value)) {
            if (opt.value.length) {
              this.showServiceDescription = true
              return false
            }
          } else if (opt.value) {
            this.showServiceDescription = true
            return false
          }
        }
        return true
      })

      this.ancestralFiles = []
      const ancestralForms = this.serviceRequestForms.filter(o => this.formName(o.formID) === 'Ancestral Tablets Engraving Notice')
      ancestralForms.forEach(form => {
        const ownEngravingImageObject = form.values.find(o => o.key === 'ownEngravingImage')
        if (ownEngravingImageObject) {
          this.ancestralFiles.push(ownEngravingImageObject.value)
        }
      })

      this.requestFormsAll = []
      this.uploadedFiles = []
      this.serviceRequestTasks.forEach(task => {
        task.taskBlocks.forEach(taskBlock => {
          if (taskBlock.block.name === 'File Upload') {
            if (taskBlock.inputValues && taskBlock.inputValues.length) {
              const obj = {
                _id: taskBlock._id,
                values: taskBlock.inputValues[0].value,
              }

              this.uploadedFiles.push(obj)
            }
          }
        })
      })
    },
    setTaskData(task, key) {
      this.assignmentErrorOptions = []
      this.assignmentError = false
      this.dateValidation = false
      this.currentKey = key
      this.currentTask = { ...task }
      if (this.currentTask.dueDate) {
        this.currentTask.dueDate = `${this.dateFormat(this.currentTask.dueDate)}, ${this.twentyFourHourTimeFormatFromDateString(this.currentTask.dueDate)}`
      } else {
        this.currentTask.dueDate = ''
      }
      this.currentTask.assignees = []
      task.assignees.forEach(assignee => {
        this.currentTask.assignees.push(assignee._id)
      })
      this.$refs['service-request-task-update-modal'].toggle('#toggle-btn')
    },

    setDutyOfficerModal() {
      this.assignmentErrorOptions = []
      this.assignmentError = false
      this.$root.$emit('bv::show::modal', 'modal-update-duty-officer', '')
    },

    checkForRequestAssigneeSchedule() {
      // eslint-disable-next-line no-unused-expressions
      this.dutyOfficerValidation === true ? this.dutyOfficerValidation = false : null
      const formData = new FormData()
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.dutyOfficer.length; i++) {
        formData.append('bulkAssignee[]', this.dutyOfficer[i])
      }

      formData.append('serviceRequests[]', this.$route.params.id)
      if (this.dutyOfficer.length) {
        this.$http.post('operation/service-requests/check/assignee/schedule', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            if (response.data.assigneeWarning.length) {
              this.assignmentErrorOptions = response.data.assigneeWarning
              this.assignmentError = true
              // this.$root.$emit('bv::show::modal', 'modal-update-assignee-all-service-request-bulk', '')
            } else {
              this.assignmentErrorOptions = []
              this.assignmentError = false
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    markTaskAsComplete(task, key) {
      this.$swal({
        title: 'Are you sure?',
        html: 'Do you want to mark task as complete? You can still edit later on.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Mark as complete',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-success ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()

            formData.append('serviceRequestTask', task._id)

            this.$http.patch('operation/service-requests/task/mark/complete', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.serviceRequestTasks[key].completed = response.data.data.completed || ''
                this.serviceRequestTasks[key].status = response.data.data.status || ''
                this.serviceRequestTasks[key].completedDate = response.data.data.completedDate || []
                this.serviceRequestTasks[key].completedBy = response.data.data.completedBy || ''
                this.serviceRequest.status = response.data.requestStatus
                this.$swal({
                  title: 'Task has been marked as Complete.',
                  html: '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    skipTask(task, key) {
      this.$swal({
        title: 'Are you sure?',
        html: 'Do you want to skip task? You can still undo later on.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Skip Task',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-success ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()

            formData.append('serviceRequestTask', task._id)

            this.$http.patch('operation/service-requests/task/mark/skip', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.serviceRequestTasks[key].skipped = response.data.data.skipped || ''
                this.serviceRequestTasks[key].status = response.data.data.status || ''
                this.serviceRequestTasks[key].skippedDate = response.data.data.skippedDate || []
                this.serviceRequestTasks[key].skippedBy = response.data.data.skippedBy || ''
                this.serviceRequest.status = response.data.requestStatus
                this.$swal({
                  title: 'Task has been skipped.',
                  html: '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    unskipTask(task, key) {
      this.$swal({
        title: 'Are you sure?',
        html: 'Do you want to unskip the task?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Unskip Task',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-success ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()

            formData.append('serviceRequestTask', task._id)

            this.$http.patch('operation/service-requests/task/mark/unskip', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.serviceRequestTasks[key].skipped = response.data.data.skipped || ''
                this.serviceRequestTasks[key].status = response.data.data.status || ''
                this.serviceRequestTasks[key].skippedDate = response.data.data.skippedDate || []
                this.serviceRequestTasks[key].skippedBy = response.data.data.skippedBy || ''
                this.serviceRequest.status = response.data.requestStatus
                this.$swal({
                  title: 'Task has been unskipped.',
                  html: '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },

    unmarkTask(task, key) {
      this.$swal({
        title: 'Are you sure?',
        html: 'Do you want to mark the task as incomplete?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Mark Incomplete',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-success ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()

            formData.append('serviceRequestTask', task._id)

            this.$http.patch('operation/service-requests/task/mark/incomplete', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.serviceRequestTasks[key].completed = response.data.data.completed || ''
                this.serviceRequestTasks[key].status = response.data.data.status || ''
                this.serviceRequestTasks[key].completedDate = response.data.data.completedDate || []
                this.serviceRequestTasks[key].completedBy = response.data.data.completedBy || ''
                this.serviceRequest.status = response.data.requestStatus
                this.$swal({
                  title: 'Task has been marked as incomplete.',
                  html: '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    submitTaskData(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.serviceRequestTaskEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.currentTask.status)
          formData.append('dueDate', this.currentTask.dueDate)
          formData.append('taskName', this.currentTask.taskName)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.currentTask.assignees.length; i++) {
            formData.append('assignees[]', this.currentTask.assignees[i])
          }

          this.$http.patch(`operation/service-requests/task/${this.currentTask._id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.$refs['service-request-task-update-modal'].toggle('#toggle-btn')
              // console.log(response.data.data.taskName)
              // this.$set(this.serviceRequestTasks[this.currentKey], 'taskName', response.data.data.taskName)
              this.serviceRequestTasks[this.currentKey].taskName = response.data.data.taskName || ''
              this.serviceRequestTasks[this.currentKey].assignees = response.data.data.assignees || []
              this.serviceRequestTasks[this.currentKey].dueDate = response.data.data.dueDate || ''
              this.serviceRequestTasks[this.currentKey].status = response.data.data.status || ''
              this.serviceRequestTasks[this.currentKey].completed = response.data.data.completed || ''
              this.serviceRequestTasks[this.currentKey].completedDate = response.data.data.completedDate || []
              this.serviceRequestTasks[this.currentKey].completedBy = response.data.data.completedBy || ''
              this.serviceRequestTasks[this.currentKey].skipped = response.data.data.skipped || ''
              this.serviceRequestTasks[this.currentKey].skippedDate = response.data.data.skippedDate || []
              this.serviceRequestTasks[this.currentKey].skippedBy = response.data.data.skippedBy || ''
              this.serviceRequest.status = response.data.requestStatus
              // this.serviceRequestTasks.splice(this.currentKey, 1, response.data.data)
              // this.$set(this.serviceRequestTasks[this.currentKey], 'taskName', response.data.data.taskName)
              // console.log(this.serviceRequestTasks[this.currentKey].taskName)
              this.currentKey = ''
              this.alreadyCheckedSchedule = false
              this.$swal({
                title: 'Task Updated!',
                html: 'The task has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'date') {
                    this.dateError = validationError.msg
                    this.dateValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    submitTaskEditForm() {
      this.$refs.serviceRequestTaskEditForm.validate().then(success => {
        if (success) {
          if (this.alreadyCheckedSchedule === true) {
            this.submitTaskData()
          } else {
            this.checkForAssigneeSchedule()
            this.alreadyCheckedSchedule = true
          }
        }
      })
    },
    checkForAssigneeSchedule() {
      // this.bulkAssigneeValidation === true ? this.bulkAssigneeValidation = false : null
      const formData = new FormData()
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.currentTask.assignees.length; i++) {
        formData.append('bulkAssignee[]', this.currentTask.assignees[i])
      }

      formData.append('dueDate', this.currentTask.dueDate)
      if (this.currentTask.assignees.length && this.currentTask.dueDate) {
        this.$http.post('operation/service-requests/task/check/assignee/schedule', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            if (response.data.assigneeWarning.length) {
              this.assignmentErrorOptions = response.data.assigneeWarning
              this.assignmentError = true
              // this.$refs['service-request-task-update-modal'].toggle('#toggle-btn')
            } else {
              this.assignmentErrorOptions = []
              this.assignmentError = false
              this.alreadyCheckedSchedule = true
              // this.$refs['service-request-task-update-modal'].toggle('#toggle-btn')
              // this.submitTaskData()
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    resolveTaskStatusVariant(status) {
      if (status === 'completed') return 'light-success'
      if (status === 'skipped') return 'light-danger'
      if (status === 'pending') return 'light-warning'
      return 'light-info'
    },
    dutyOfficerSubmitForm() {
      this.$refs.serviceRequestDutyOfficerEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.dutyOfficer.length; i++) {
            formData.append('assignees[]', this.dutyOfficer[i])
          }

          this.$http.patch(`operation/service-requests/${this.serviceRequestID}/assignee/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.serviceRequest.assignees = response.data.data.assignees || {}
              // this.serviceRequest.activityLog = response.data.data.activityLog || []
              this.setData()
              // this.$nextTick(() => {
              //   this.$refs['update-status-modal'].toggle('#toggle-btn')
              // })
              this.$swal({
                title: 'Assignee Updated!',
                html: `Assignee for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'assignees') {
                    this.dutyOfficerError = validationError.msg
                    this.dutyOfficerValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },

    submitForm() {
      this.$refs.allserviceRequestStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`operation/service-requests/${this.serviceRequestID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.serviceRequest.status = response.data.data.status || 'completed'
              // @ts-checkthis.serviceRequest.activityLog = response.data.data.activityLog || []
              this.setData()
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
  .low-opacity {
    opacity: 0.6;
  }
  .high-opacity div {
    opacity: 1 !important;
  }
  .padding-two {
    padding: 2rem !important;
  }
  .padding-one {
    padding: 1.5rem !important;
  }
  .shadow-background {
    background: #F5F8FB;
  }
  .purple-button {
    background: #9B5899 !important;
  }
  .purple-row {
    background: rgba(155, 88, 153, 0.12);
  }
  .no-background {
    background: none !important;
  }
  .background-card {
    background: #9FB8D8;
    border-radius: 6px;
  }

  .red-trash-button {
    color: #D91B35 !important;
  }
  .shadow-body {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06) !important;
    border-radius: 6px;
    padding: 1.5rem !important;
  }

  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }

  .link-tag {
    color: #104D9D;
  }

  .autosuggest__results-container .autosuggest__results {
    background-color: #fff;
    margin-top: 1rem;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
  }
  .autosuggest__results-container .autosuggest__results {
    position: absolute;
    width: 100%;
    overflow-y: auto;
    max-height: 40vh;
  }
</style>
